<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit City</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="city"
              class="w-full"
              label="City Name (*)"
              v-model="payload.city"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="province"
              class="w-full"
              label="Province (*)"
              v-model="payload.province"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="mgCode"
              class="w-full"
              label="MG Code (*)"
              v-model="payload.mgCode"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="lat"
              class="w-full"
              type="number"
              label="Latitude (*)"
              v-model="payload.lat"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="lng"
              type="number"
              class="w-full"
              label="Latitude (*)"
              v-model="payload.lng"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import moduleCityManagement from "@/store/city-management/moduleCityManagement.js";

export default {
  name: "CityEdit",

  metaInfo: {
    title: "Edit City",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  data: () => ({
    payload: {
      city: "",
      province: "",
      mgCode: "",
      lng: "",
      lat: "",
    }
  }),

  methods: {
    getData () {
      if (this.$route.params.id) {
        this.$store.dispatch('cityManagement/fetchCity', this.decryptedID)
          .then(({data: res}) => {
            this.payload = res.data
          })
          .catch(err => this.$catchErrorResponse(err))
          .finally(() => {
            this.$vs.loading.close()
          })
      }
    },

    storeData() {
      const payload = { ...this.payload };
      payload.lng = parseFloat(payload.lng);
      payload.lat = parseFloat(payload.lat);
      let dispatch = "cityManagement/updateCity";
      if (!this.$route.params.id) {
        dispatch = "cityManagement/storeCity"
      } else {
        payload.id = this.decryptedID;
      }
      this.$store
        .dispatch(dispatch, payload)
        .then((res) => {
          console.log(res)
          this.$vs.notify({
            title: "Successful",
            text: res.message,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/cities");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!moduleCityManagement.isRegistered) {
      this.$store.registerModule("cityManagement", moduleCityManagement);
      moduleCityManagement.isRegistered = true;
    }

    this.getData()
  }
};
</script>

<style></style>
